import axios, { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse, ApiSeo } from '.';

export interface ApiQuestion extends ApiSeo {
    id: number,
    name: string,
    content: any[],
    isSticky: boolean,
    personas: any[],
    hits: number,
    tags?: string[],
    promomatsNumber?: string,
    thumbsUp?: number,
    thumbsDown?: number,
}

const rest = {
    delete: (questionId: number | string): AxiosPromise => {
        return axios.delete(`questions/${questionId}`);
    },
    get: (questionId: number, isFront: boolean = false): AxiosPromise<ApiQuestion> => {
        return axios.get(`${isFront ? 'front/' : ''}questions/${questionId}`);
    },
    list: (
        query?: { [key: string]: any },
        qWith?: string,
        isFront?: boolean,
    ): AxiosPromise<ApiPaginatedResponse<ApiQuestion[]>> => {
        return axios.get(`${isFront ? 'front/' : ''}questions?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    put: (question: Partial<ApiQuestion>): AxiosPromise<ApiQuestion> => {
        return axios.post(`questions${question.id ? `/${question.id}` : ''}`, question);
    },
}

export default rest;
