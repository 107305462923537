import { faIconsAlt } from '@fortawesome/pro-regular-svg-icons';
import React, { FC } from 'react';
import { getValue } from '../../../../lib/block';
import { BlockProps, Segment } from '../../../../RbKit';
import ImageThumb from '../../../../components/ImageThumb';

const Block: FC<BlockProps> = ({ data }): JSX.Element => {
    return (
        <Segment>
            <div style={{ display: 'flex', justifyContent: getValue(data, 'position') === 'left' ? 'flex-start' : 'flex-end' }}>
                <ImageThumb
                    square
                    image={getValue(data, 'image')}
                />
            </div>
        </Segment>
    );
}

const data = {
    Block,
    icon: faIconsAlt,
    getData: () => ({
        block: 'icon',
        fields: [{
            id: 'image',
            type: 'image',
            label: 'Icon image',
            value: '',
        }, {
            id: 'position',
            type: 'dropdown',
            label: 'Position',
            props: {
                options: [{
                    text: 'Left',
                    value: 'left',
                }, {
                    text: 'Right',
                    value: 'right',
                }]
            },
            value: 'left',
        }],
    })
};

export default data;
