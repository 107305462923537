import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { find } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { Button, Modal } from '../../RbKit';
import SortableList, { reorder } from '../../components/SortableList';
import BlockContainer from './BlockContainer';
import Icon from './Blocks/Icon';
import Text from './Blocks/Text';
import Video from './Blocks/Video';
import Sidebar from './Sidebar';
import styles from './styles.module.scss';
const availableBlocks = process.env.REACT_APP_AVAILABLE_BLOCKS?.split(',');

const BLOCKS: any = {
    'Text': Text,
    'Icon': Icon,
    'Video': Video,
};

interface ContentEditorProps {
    blocks?: any[],
    onUpdate: (items: any[]) => void,
}

const ContentEditor: FC<ContentEditorProps> = ({ blocks, onUpdate }): JSX.Element => {
    const [ confirm, setConfirm ] = useState<number | boolean>(false);
    const [ editMode, setEditMode ] = useState<boolean>(false);
    const [ items, setItems ] = useState<any[]>(blocks || []);
    const [ showAddModal, setShowAddModal ] = useState<number | boolean>(false);

    useEffect(() => setItems(blocks || []), [blocks]);

    const onDragEnd = (result: any): void => {
        if (result.destination) {
            const newItems = reorder(items, result.source.index, result.destination.index);
            setItems(newItems);
            onUpdate(newItems);
        }
    }

    const handleBlockAdd = (type: string, index?: number): void => {
        const newItems = [...items];
        const newBlock = {
            id: `${type}-${Math.random().toString(36).substring(2,7)}`,
            ...BLOCKS[type].getData()
        };
        
        newItems.splice(index || (items.length - 1), 0, newBlock);
        setItems(newItems);
        
        setShowAddModal(false);
        onUpdate(newItems);
    }

    const handleBlockEdit = (id: string): void => {
        const block = find([...items], { id });
        dispatchEvent(new CustomEvent('set-editable-block', { detail: { block } }));
        setEditMode(true);
    }

    const handleBlockDelete = (index: number): void => {
        const newItems = [...items.filter((o, i) => i !== index)];
        setItems(newItems);
        setConfirm(false);
        onUpdate(newItems);
    }

    return (<>
        <Sidebar
            onClose={() => {
                setEditMode(false);
                onUpdate(items);
            }}
            open={editMode}
        />
        <div className={styles.container}>
            <SortableList
                customHandle
                items={items}
                onUpdate={onDragEnd}
                renderListItem={(item: any, index: number, handle: any) => (
                    <BlockContainer
                        handle={handle}
                        onDelete={() => setConfirm(index)}
                        onEdit={() => handleBlockEdit(item.id)}
                        onPlus={() => setShowAddModal(index + 1)}
                    >
                        {item.block === 'text' && <Text.Block data={item} />}
                        {item.block === 'icon' && <Icon.Block data={item} />}
                        {item.block === 'video' && <Video.Block data={item} />}
                    </BlockContainer>
                )}
            />
            <div className={styles.button}>
                <Button
                    label="Add new block"
                    onClick={() => setShowAddModal(items.length)}
                />
            </div>
        </div>
        <Modal
            header="Add new content block"
            onClose={() => setShowAddModal(false)}
            open={showAddModal !== false}
            size="medium"
        >
            <div className={styles.modalBlocks}>
                {availableBlocks?.map((block) => (
                    <div
                        className={styles.modalBlock}
                        key={`add-block-${block}`}
                        onClick={() => handleBlockAdd(block, showAddModal as number)}
                    >
                        <div>
                            <FontAwesomeIcon icon={BLOCKS[block].icon} />
                            {block}
                        </div>
                    </div>
                ))}
            </div>
        </Modal>
        <Modal
            footer={(
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        label="Cancel"
                        link
                        onClick={() => setConfirm(false)}
                    />
                    <Button
                        label="Ok"
                        primary
                        onClick={() => handleBlockDelete(confirm as number)}
                    />
                </div>
            )}
            header="Are you sure?"
            onClose={() => setConfirm(false)}
            open={confirm !== false}
            size="small"
        >
            Are you sure you wish to delete this block? You still need to click save to make it permanent.
        </Modal>
    </>);
}

export default ContentEditor;
