import axios, { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse } from '.';

export interface ApiAlert {
    id: number,
    name: string,
    content: any[],
    isActive: boolean,
}

const rest = {
    delete: (alertId: number): AxiosPromise => {
        return axios.delete(`alerts/${alertId}`);
    },
    get: (alertId: number): AxiosPromise<ApiAlert> => {
        return axios.get(`alerts/${alertId}`);
    },
    list: (
        query?: { [key: string]: any },
        qWith?: string,
        isFront?: boolean,
    ): AxiosPromise<ApiPaginatedResponse<ApiAlert[]>> => {
        return axios.get(`${isFront ? 'front/' : ''}alerts?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    put: (alert: Partial<ApiAlert>): AxiosPromise<ApiAlert> => {
        return axios.post(`alerts${alert.id ? `/${alert.id}` : ''}`, alert);
    },
}

export default rest;
