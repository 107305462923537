import axios from 'axios';
import alert from './alert';
import auth from './auth';
import persona from './persona';
import question from './question';
import feedback from './feedback';
import image, { ApiImage } from './image';
import user from './user';
import tag from './tag';

export const getToken = () => {
    if (window.self === window.top && localStorage.getItem('accessToken')) {
        return localStorage.getItem('accessToken');
    }
    
    return null;
}

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.headers.common = { 'X-Requested-With': 'XMLHttpRequest' };

axios.interceptors.request.use((config) => {
    const accessToken = getToken(); 
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
});

axios.interceptors.response.use((res: any) => res, (err: any) => {
    if (err.message.indexOf(401) !== -1) {
        localStorage.removeItem('accessToken');
    } else if (err.message.indexOf(403) !== -1) {
        window.location.href = '/';
    }
    return Promise.reject(err);
});

export interface ApiMeta {
    current_page: number,
    from: number,
    last_page: number,
    path: string,
    per_page: number,
    to: number,
    total: number,
}

export interface ApiSeo {
    seoTitle: string,
    seoDescription: string,
    seoUrl: string,
    seoImageId?: number,
    seoImage?: ApiImage,
}

export interface ApiPaginatedResponse<T> {
    data: T,
    meta: ApiMeta,
    links: {
        first: string,
        last: string,
        next?: string,
        prev?: string,
    },
}

export const request = axios;

const api = {
    getProfile: auth.getProfile,
    login: auth.login,
    logout: auth.logout,
    
    deleteAlert: alert.delete,
    getAlert: alert.get,
    listAlerts: alert.list,
    putAlert: alert.put,

    deleteQuestion: question.delete,
    getQuestion: question.get,
    listQuestions: question.list,
    putQuestion: question.put,
    
    deletePersona: persona.delete,
    getPersona: persona.get,
    listPersonas: persona.list,
    putPersona: persona.put,
    
    deleteImage: image.delete,
    getImage: image.get,
    listImages: image.list,
    uploadImage: image.upload,
    
    suggestTag: tag.suggest,

    deleteUser: user.delete,
    getUser: user.get,
    listUsers: user.list,
    putUser: user.put,

    sendFeedback: feedback.send,
};

export default api;
