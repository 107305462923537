import { faVimeoSquare } from '@fortawesome/free-brands-svg-icons';
import React, { FC } from 'react';
import { getValue } from '../../../../lib/block';
import { BlockData, BlockProps, Segment } from '../../../../RbKit';

const parseVimeoId = (url: string) => {
    const split = url.split('?')[0].replace(/\/$/, '').split('/');
    return split[split.length - 1];
}

export const parser = (data: BlockData): JSX.Element => {
    return (
        <div style={{ paddingBottom: '56.25%', position: 'relative' }}>
            <iframe
                allow="autoplay; fullscreen; picture-in-picture"
                src={`https://player.vimeo.com/video/${parseVimeoId(getValue(data, 'videoId'))}`}
                style={{ width: '100%', border: 0, height: '100%', position: 'absolute' }}
                title="Vimeo Video"
            />
        </div>
    );
}

const Block: FC<BlockProps> = ({ data }): JSX.Element => {
    return (
        <Segment>
            {parser(data)}
        </Segment>
    );
}

const data = {
    Block,
    icon: faVimeoSquare,
    getData: () => ({
        block: 'video',
        fields: [{
            id: 'videoId',
            type: 'text',
            label: 'Vimeo URL',
            value: 'https://vimeo.com/259411563',
        }],
    })
};

export default data;
