import axios, { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse } from '.';
import { ApiImage } from './image';

export interface ApiPersona {
    id: number,
    name: string,
    imageId: number,
    image?: ApiImage,
}

const rest = {
    delete: (questionId: number | string): AxiosPromise => {
        return axios.delete(`personas/${questionId}`);
    },
    get: (questionId: number): AxiosPromise<ApiPersona> => {
        return axios.get(`personas/${questionId}`);
    },
    list: (
        query?: { [key: string]: any },
        qWith?: string,
        isFront?: boolean,
    ): AxiosPromise<ApiPaginatedResponse<ApiPersona[]>> => {
        return axios.get(`${isFront ? 'front/' : ''}personas?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    put: (question: Partial<ApiPersona>): AxiosPromise<ApiPersona> => {
        return axios.post(`personas${question.id ? `/${question.id}` : ''}`, question);
    },
}

export default rest;
