import React, { FC, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { NotificationCenter } from './RbKit';
import api from './api';
import TopBar from './modules/TopBar';
import Drawer from './modules/Drawer';
import Loader from './RbKit/elements/Loader';
import { AppState } from './store';
import styles from './styles.module.scss';
import UserListView from './views/admin/UserView';
import UserEditView from './views/admin/UserView/Edit';
import LoginView from './views/auth/LoginView';
import PersonaEditView from './views/PersonaView/Edit';
import PersonaListView from './views/PersonaView';
import QuestionListView from './views/QuestionView';
import QuestionEditView from './views/QuestionView/Edit';
import AlertListView from './views/AlertView';
import AlertEditView from './views/AlertView/Edit';
import MediaListView from './views/MediaView';
import MediaViewModal from './views/MediaView/Modal';
import WebsiteView from './views/WebsiteView';
import SearchLogView from './views/SearchLogView';
import { hasRole } from './lib/auth';

const App: FC = (): JSX.Element => {
    const [ isLoading, setIsloading ] = useState<boolean>(true);
    const profile = useSelector((state: AppState) => state.auth.profile);

    useEffect(() => {
        if (!process.env.REACT_APP_IFRAME) {
            api.getProfile().then(() => setIsloading(false)).catch(() => setIsloading(false));
        } else {
            setIsloading(false);
        }
    }, []);

    return (
        <Router>
            {!process.env.REACT_APP_IFRAME && <TopBar />}
            {isLoading ? (
                <Loader />
            ) : !process.env.REACT_APP_IFRAME && profile ? (<>
                <Drawer />
                <main className={styles.main}>
                    <div>
                    <Switch>
                        <Route component={AlertListView} exact path="/alerts" />
                        <Route component={AlertEditView} exact path="/alerts/create" />
                        <Route component={AlertEditView} exact path="/alerts/:id/edit" />

                        <Route component={PersonaListView} exact path="/personas" />
                        <Route component={PersonaEditView} exact path="/personas/create" />
                        <Route component={PersonaEditView} exact path="/personas/:id/edit" />

                        <Route component={QuestionListView} exact path="/questions" />
                        <Route component={QuestionEditView} exact path="/questions/create" />
                        <Route component={QuestionEditView} exact path="/questions/:id/edit" />

                        <Route component={MediaListView} exact path="/media/images" />
                        
                        {hasRole(profile, 'Administrator') && (<>
                            <Route component={UserListView} exact path="/admin/users" />
                            <Route component={UserEditView} exact path="/admin/users/create" />
                            <Route component={UserEditView} exact path="/admin/users/:id/edit" />

                            <Route component={SearchLogView} exact path="/admin/search-log" />
                        </>)}
                    </Switch>
                    </div>
                </main>
                <MediaViewModal open={false} />
            </>) : (
                <Switch>
                    {process.env.REACT_APP_IFRAME
                        ? <Route component={WebsiteView} path="*" />
                        : <Route component={LoginView} path="*" />
                    }
                </Switch>
            )}
            <NotificationCenter />
        </Router>
    )
}

export default App;
