import axios, { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse } from '.';

export interface ApiFeedback {
    id: number,
    type: number,
    content?: string,
}

const rest = {
    send: (questionId: number, type: number, content?: string): AxiosPromise<ApiFeedback> => {
        return axios.post(`front/feedback/${questionId}`, {
            type,
            content,
        });
    },
}

export default rest;
